import React, { useState } from 'react';
import './Style.css';
import html2canvas from 'html2canvas';

function App() {
	const [ title, setTitle ] = useState<string>('');
	const [ subTitle, setSubTitle ] = useState<string>('');
	const [ icon, setIcon ] = useState<File | null>(null);
	
	return (
		<div className="App">
			<div>
				<Preview title={ title } icon={ icon } subTitle={ subTitle } />
				<div className={ 'Control' }>
					<input type={ 'text' } value={ title } onChange={ (e) => {
						if (e.target.value.length > 15) {
							alert('15자 이하로 입력해주세요.');
							return;
						}
						
						setTitle(e.target.value);
					} } placeholder={ '제목을 입력해 주세요.' } />
					<input type={ 'text' } value={ subTitle } onChange={ (e) => {
						if (e.target.value.length > 30) {
							alert('30자 이하로 입력해주세요.');
							return;
						}
						
						setSubTitle(e.target.value);
					} } placeholder={ '부제목을 입력해 주세요.' } />
					<input type={ 'file' } onChange={ (e) => {
						const file = e.target.files?.[0];
						
						if (file) setIcon(file);
					} } />
				</div>
			</div>
			<div className={ 'toImage' }>
				<button onClick={ () => {
					const preview = document.querySelector('.Preview') as HTMLElement;
					html2canvas(preview, {
						width: 1920,
						height: 1080,
					}).then((canvas) => {
						saveImg(canvas.toDataURL('image/jpg', 1.0), 'thumbnail.jpg');
					});
					
					const saveImg = (uri: any, filename: string) => {
						const link = document.createElement('a');
						document.body.appendChild(link);
						
						link.href = uri;
						link.download = filename;
						link.click();
						
						document.body.removeChild(link);
					};
				} }>이미지로 저장하기
				</button>
			</div>
		</div>
	);
}

interface PreviewProps {
	title: string;
	subTitle: string;
	icon: File | null;
}

function Preview({ title, subTitle, icon }: PreviewProps) {
	return (
		<div className="Preview">
			<div>
				{
					icon && (
						<div className={ 'Icon' }>
							<img src={ URL.createObjectURL(icon) }
							     alt={ 'icon' }
							     width={ '150px' } />
						</div>
					)
				}
				<div className={ 'Title' }>{ title }</div>
				{
					(subTitle === '') || (
						<div className={ 'SubTitle' }>
							<div className={ 'Line' }></div>
							{ subTitle }
						</div>
					)
				}
			</div>
		</div>
	);
}

export default App;
